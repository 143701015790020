import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { setUser, getReplay } from '@sentry/vue';
import { AxiosError } from 'axios';
import { api } from 'boot/axios';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import { Notify, Quasar } from 'quasar';
import { LogoutResponse } from 'src/types/responses';
import { $t, $te } from 'src/boot/i18n';
import { AppError } from 'src/helpers';
import { roundNotifications } from '@quasar/extras/material-icons-round';

let dismissLostConnection = undefined as
  | undefined
  | ReturnType<Notify['create']>;

function showLostSignalR() {
  if (dismissLostConnection == undefined) {
    dismissLostConnection = Notify.create({
      message: $t('error.problem_on_signalr'),
      type: 'info',
      timeout: 0,
    });
  }
}

function hideLostSignalR() {
  if (dismissLostConnection != undefined) {
    dismissLostConnection();
    dismissLostConnection = undefined;
  }
}

// eslint-disable-next-line
type PolicyArgs<T extends (...args: any) => any> =
  Parameters<T>['0']['$arguments'];

type MyACL = {
  'recipients.save': unknown;
  'wallets.create': unknown;
  'become-partner': unknown;
  // 'partner.become-partner-again': unknown;
  'wallets.deposit-money': PolicyArgs<typeof canWalletAddMoney>;
  'wallets.convert-money': PolicyArgs<typeof canWalletConvertMoney>;
  'wallets.send-money': PolicyArgs<typeof canWalletSendMoney>;
  'quick-transfer': PolicyArgs<typeof canQuickTransfer>;
};

let fetch_wallet: Promise<[void, void]> | undefined = undefined;
// let fetch_notifs: Promise<void> | undefined = undefined;

export const useAuth = defineStore('auth', {
  state: () => {
    const token = localStorage.getItem('token') as string;
    const refresh_token = localStorage.getItem('refresh_token') as string;
    const signalr_token = localStorage.getItem('signalr_token') as string;
    const hide_partner_box = Boolean(localStorage.getItem('hide_partner_box'));

    const push_permission =
      'Notification' in window ? Notification.permission : 'default';

    return {
      push_permission: push_permission,
      user: undefined as unknown as UserModel,
      wallets: undefined as unknown as WalletsModel,
      wallets_insight: undefined as unknown as WalletsInsightModel,
      token: token,
      refresh_token: refresh_token,
      signalr_token: signalr_token,
      signalr: undefined as undefined | HubConnection,
      return_url: undefined,
      login_state: undefined as undefined | boolean,
      hide_partner_box: hide_partner_box,
      unread_notif_count: {
        partner: undefined as unknown as number,
        consumer: undefined as unknown as number,
      },
      api_version: undefined as undefined | string,
    };
  },

  actions: {
    async isLogin(force?: boolean) {
      try {
        if (!this.token || this.token == '') {
          removeAuthHeader();
          return false;
        }

        if (force == undefined && this.login_state != undefined)
          return this.login_state;

        this.setAuthHeader(this.token);

        const temp_user = await getProfile();

        if (
          !this.user ||
          Object.entries(this.user).sort().toString() !==
            Object.entries(temp_user).sort().toString()
        ) {
          this.user = temp_user;
        }

        // Set sentry context for user
        // Ignore for dev
        if (!process.env.DEV) {
          setUser({ id: this.user.user_id, email: this.user.email });
        }

        const manager = useLanguage();

        if (this.user.locale) {
          const lang = manager.mapLocale(this.user.locale);
          if (lang != Quasar.lang.isoName || manager.loaded == false) {
            manager.setLocale(lang);
          }
        }

        this.login_state = true;

        try {
          void this.getWallets();
        } catch {
          this.wallets = [];
        }

        void this.fetchUnreadNotifCount();

        // try {
        //   void this.fetchUnreadNotifCount();
        // } catch (error) {
        //   this.unread_notif_count = { partner: 0, consumer: 0 };
        // }

        // reset subpage for re-login
        // const subpage = useSubpage();
        // subpage.reset();

        this.requestPushAccess();

        void this.registerSignalR();

        return true;
      } catch (error) {
        if ((error as AxiosError)?.response?.status != 401) {
          Notify.create({
            type: 'negative',
            message: (error as Error).message,
          });
        }

        this.login_state = false;
        this.unbindProfileUpdateEvent();
        removeAuthHeader();

        return false;
      }
    },
    cleanSession() {
      this.login_state = false;

      localStorage.removeItem('token');
      this.token = '';

      localStorage.removeItem('signalr_token');
      this.signalr_token = '';

      localStorage.removeItem('refresh_token');
      this.refresh_token = '';

      localStorage.removeItem('hide_partner_box');

      this.hide_partner_box = false;
    },
    async registerSignalR() {
      try {
        const connection = new HubConnectionBuilder()
          .withUrl(
            `${process.env.SIGNALR_BASEURL}/signal?token=${this.signalr_token}`,
          )
          // .withAutomaticReconnect([0, 5000, 10000, 20000, 30000])
          .build();

        this.signalr = connection;

        // eslint-disable-next-line
        const that = this;
        async function startConnection() {
          try {
            if (that.login_state) await connection.start();

            hideLostSignalR();
          } catch {
            console.log('Connection failed. Retrying in 5 seconds...');

            showLostSignalR();
            setTimeout(startConnection, 5000);
          }
        }

        connection.onclose(async () => {
          console.log('SignalR connection lost. Attempting to reconnect...');
          // showLostSignalR();
          await startConnection();
        });

        startConnection();

        this.bindProfileUpdateEvent();
      } catch {
        showLostSignalR();
      }
    },
    requestPushAccess(flag = false) {
      if ('Notification' in window) {
        void Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            // this.$q.notify({
            //   // eslint-disable-next-line
            //   message: 'Notification permission granted.',
            //   type: 'info',
            // });
            this.listenToPush();
          } else {
            if (flag) {
              Notify.create({
                // eslint-disable-next-line
                message: $t('push_permission_denied'),
                type: 'negative',
              });
            }
          }
          this.push_permission =
            'Notification' in window ? Notification.permission : 'default';
        });
      }

      //
    },
    listenToPush() {
      // Your web app's Firebase configuration
      const FIREBASE_CREDENTIALS_HERE = {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_AUTH_DOMAIN,
        projectId: process.env.FIREBASE_PROJECT_ID,
        storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.FIREBASE_MESSAGIN_SENDER_ID,
        appId: process.env.FIREBASE_APP_ID,
      };

      const YOUR_PUBLIC_VAPID_KEY_HERE = process.env.FB_VAPID;

      initializeApp(FIREBASE_CREDENTIALS_HERE);

      const messaging = getMessaging();

      getToken(messaging, { vapidKey: YOUR_PUBLIC_VAPID_KEY_HERE })
        .then((currentToken) => {
          if (currentToken) {
            void updatePushToken(currentToken);
            // Send the token to your server and update the UI if necessary
          } else {
            console.log('No push registration token available.');
            // Show permission request UI
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });

      onMessage(messaging, (/*payload*/) => {
        // console.log('Message received.', payload);

        // const notification = payload.notification;
        void this.fetchUnreadNotifCount();

        if ($te('pwa.got_new_notif'))
          Notify.create({
            // eslint-disable-next-line
            // message: `${notification?.title}: ${notification?.body}`,
            message: $t('got_new_notif'),
            color: 'white',
            textColor: 'primary',
            progress: true,
            icon: roundNotifications,
            classes: 'my-notify hide-close',
            actions: [
              {
                label: $t('view'),
                color: 'secondary',
                handler: () => {
                  void (
                    this as unknown as {
                      router: ReturnType<typeof useRouter>;
                    }
                  ).router.push({ name: 'notifications' });
                },
              },
              {
                label: $t('dismiss'),
                // color: 'white',
                handler: () => {
                  /* ... */
                },
              },
            ],
          });
      });
    },
    bindProfileUpdateEvent() {
      this.unbindProfileUpdateEvent();
      this.signalr?.on('kyc-update', this.forceRefreshProfile);
      this.signalr?.on('partnership-update', this.forceRefreshProfile);
      this.signalr?.on('user-token-expire', this.forceLogoutProfile);
    },
    unbindProfileUpdateEvent() {
      this.signalr?.off('kyc-update', this.forceRefreshProfile);
      this.signalr?.off('partnership-update', this.forceRefreshProfile);
      this.signalr?.off('user-token-expire', this.forceLogoutProfile);
    },
    cleanState() {
      fetch_wallet = undefined;
      this.user = {} as UserModel;
      this.wallets = null as unknown as WalletsModel;
      this.wallets_insight = null as unknown as WalletsInsightModel;
      const $subpage = useSubpage();
      $subpage.reset();
      const $meta = useMetaTag();
      $meta.set({ title: undefined });
    },
    unregisterSignalr() {
      hideLostSignalR();
      void this.signalr?.stop();
    },
    async logout(logout_all: boolean) {
      const refresh_token = logout_all ? undefined : this.refresh_token;
      this.unbindProfileUpdateEvent();

      this.unregisterSignalr();

      this.cleanSession();

      this.cleanState();

      await api.post<LogoutResponse>(
        '/api/logout',
        {},
        {
          params: {
            refreshToken: refresh_token,
          },
        },
      );

      removeAuthHeader();

      const replay = getReplay();
      replay?.flush();
      setUser(null);
    },

    hidePartnerBox() {
      this.hide_partner_box = true;
      localStorage.setItem('hide_partner_box', 'true');
    },

    checkActivePartner() {
      if (this.is_active_partner == false) {
        throw new AppError(
          $t('partner_cannot_do', {
            partner_status: this.user.partnership_status,
          }),
          {
            cause: 'need_active_partner',
          },
        );
      }
    },

    isActiveClient() {
      if (
        // this.user.is_phone_verified &&
        (this.user.current_kyc_level == 'Pro' &&
          this.user.kyc_status == 'Verified') ||
        this.user.current_kyc_level == 'Advance'
      )
        return true;

      return false;
    },

    forceRefreshProfile() {
      return this.isLogin(true);
    },
    async forceLogoutProfile() {
      await this.forceRefreshProfile();
      void (
        this as unknown as {
          router: ReturnType<typeof useRouter>;
        }
      ).router.push({ name: 'login' });
    },

    // checkActiveClient() {
    //   if (this.isActiveClient() == false) {
    //     throw new AppError(
    //       $t('acl.need_upgrade', { level: $t('kyc_levels.Pro') }),
    //       {
    //         actions: [
    //           {
    //             label: $t('complete_kyc'),
    //             color: 'yellow',
    //             handler: () => {
    //               void (
    //                 this as unknown as { router: ReturnType<typeof useRouter> }
    //               ).router.push({ name: 'profile.edit' });
    //             },
    //           },
    //         ],
    //         cause: 'acl_access_denied',
    //       },
    //     );
    //   }
    // },

    can<K extends keyof MyACL>($ability: K, $arguments?: MyACL[K]) {
      const $router = (
        this as unknown as {
          router: ReturnType<typeof useRouter>;
        }
      ).router;

      const $acl = {
        'recipients.save': canRecipientSave,
        'wallets.create': canWalletCreate,
        'become-partner': canBecomePartner,
        // 'partner.become-partner-again': canBecomePartner,
        'wallets.deposit-money': canWalletAddMoney,
        'wallets.convert-money': canWalletConvertMoney,
        'wallets.send-money': canWalletSendMoney,
        'quick-transfer': canQuickTransfer,
      };

      if ($ability in $acl) {
        const fn = $acl[$ability as keyof typeof $acl] as PolicyRule;

        return fn({
          $arguments: $arguments as Parameters<PolicyRule>[0]['$arguments'],
          $auth: this,
          $router: $router,
        });
      }

      return false;

      // switch ($ability) {
      //   case 'recipients.save':
      //     return canRecipientSave($router);

      //   case 'wallets.create':
      //     return canWalletCreate();

      //   case 'wallets.deposit-money':
      //     return canWalletAddMoney(
      //       $arguments as Parameters<typeof canWalletAddMoney>[0],
      //     );

      //   case 'wallets.convert-money':
      //     return canWalletConvertMoney(
      //       $arguments as Parameters<typeof canWalletConvertMoney>[0],
      //     );

      //   case 'wallets.send-money':
      //     return canWalletSendMoney(
      //       $arguments as Parameters<typeof canWalletSendMoney>[0],
      //     );
      // }
    },

    authorize<K extends keyof MyACL>(
      $ability: K,
      $arguments?: MyACL[K],
      $params?: {
        onDenied?: () => void;
        message?: string;
        actions?: { label?: string; color: string; handler: () => void }[];
      },
    ) {
      if (!this.can($ability, $arguments)) {
        if ($params?.onDenied) $params?.onDenied();

        let message;
        let actions;

        if ($params?.message) message = $params.message;
        else
          switch (this.user.kyc_level) {
            // case 'Basic':
            //   message = $t('acl.need_upgrade', { level: $t('kyc_levels.Pro') });
            //   break;

            // case 'Pro':
            //   message = $t('acl.need_upgrade', {
            //     level: $t('kyc_levels.Advance'),
            //   });
            //   break;

            default:
              message = $t('acl.unauthorize_access');
              // actions = [
              //   {
              //     label: $t('complete_kyc'),
              //     color: 'yellow',
              //     handler: () => {
              //       void (
              //         this as unknown as {
              //           router: ReturnType<typeof useRouter>;
              //         }
              //       ).router.push({ name: 'profile.kyc' });
              //     },
              //   },
              // ];
              break;
          }

        // if ($params?.actions) actions = $params.actions;
        // else
        //   switch (this.user.kyc_level) {
        //     case 'Basic':
        //     case 'Pro':
        //       actions = [
        //         {
        //           label: $t('complete_kyc'),
        //           color: 'yellow',
        //           handler: () => {
        //             void (
        //               this as unknown as {
        //                 router: ReturnType<typeof useRouter>;
        //               }
        //             ).router.push({ name: 'profile.kyc' });
        //           },
        //         },
        //       ];
        //       break;
        //   }

        throw new AppError(message, {
          actions: actions ?? [],
          cause: 'unauthorize_access',
        });
      }
    },

    async getWallets() {
      if (this.wallets == undefined) {
        if (fetch_wallet == undefined) {
          fetch_wallet = this.fetchWallets();
          await fetch_wallet;
        } else {
          // We already trying to load the data
          await fetch_wallet;
        }
      }

      return this.wallets;
    },

    // getFullName() {
    //   if (this.user.first_name || this.user.last_name) {
    //     return `${this.user.first_name} ${this.user.last_name}`;
    //   } else {
    //     return this.user.email;
    //   }
    // },

    async fetchUnreadNotifCount() {
      try {
        this.unread_notif_count = await getUnreadNotifCount();
      } catch {
        // skip error for this
      }
    },

    async fetchWallets() {
      const insight = (async () => {
        this.wallets_insight = await getWalletsInsight();
      })();
      const wallets = (async () => {
        this.wallets = await getWallets();
      })();

      return Promise.all([wallets, insight]);
    },

    setAuthHeader(token: string) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    },

    setLoginState(state: boolean) {
      this.login_state = state;
    },

    storeLogin(token: string, refresh_token: string, signalr_token: string) {
      this.setAuthHeader(token);

      // For Home.Vue
      localStorage.removeItem('hide_partner_box');

      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem('signalr_token', signalr_token);
      this.token = token;
      this.refresh_token = refresh_token;
      this.signalr_token = signalr_token;
      this.login_state = undefined;

      return true;
    },

    async requestSignIn(form: {
      email: string;
      password: string;
      captcha: AppCaptchaValueModel;
    }) {
      const res = await requestSignIn(form);

      if (
        res.token != undefined &&
        res.refresh_token != undefined &&
        res.signalr_token != undefined
      ) {
        return this.storeLogin(
          res.token,
          res.refresh_token,
          res.signalr_token!,
        );
      } else if (res.first_step_token != undefined) return res.first_step_token;
      else throw Error('invalid_signin');
    },

    async signIn(form: { token: string; otp: number }) {
      const res = await signIn(form);

      return this.storeLogin(res.token, res.refresh_token, res.signalr_token);
    },
  },

  getters: {
    is_active_partner() {
      if (this.user.partnership_status == 'Verified') return true;

      return false;
    },
    total_unread_notif_count(state) {
      return (
        state.unread_notif_count.consumer + state.unread_notif_count.partner
      );
    },
  },
});

function removeAuthHeader() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  api.defaults.headers.common['Authorization'] = '';
}
