export function canRecipientSave({
  // eslint-disable-next-line
  $auth,
  // eslint-disable-next-line
  $router,
}: Parameters<PolicyRule>[0]) {
  // if ($auth.user.kyc_level == 'Basic') {
  //   Dialog.create({
  //     component: DialogAlert,
  //     componentProps: {
  //       title: $t('add_recipient'),
  //       message: $t('acl.add_recipient_needs_pro_level'),
  //       buttons: [
  //         {
  //           label: $t('complete_kyc'),
  //           color: 'primary',
  //           type: 'dismiss',
  //           click: () => {
  //             void $router.push({ name: 'profile.kyc' });
  //           },
  //         },
  //       ],
  //     },
  //   });
  //   return false;
  // }

  return true;
}
