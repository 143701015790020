/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { register } from 'register-service-worker';
import { Notify } from 'quasar';
import package_info from 'src/../package.json';
import { $t, $te } from 'src/boot/i18n';

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
declare let window: {
  // eslint-disable-next-line
  [x: string]: any;
  location: {
    reload: () => void;
  };
};

function appUpdated() {
  let wait_for_load_i18n: ReturnType<typeof setInterval> =
    undefined as unknown as ReturnType<typeof setInterval>;
  wait_for_load_i18n = setInterval(() => {
    if ($te('pwa.reload_to_new')) {
      clearInterval(wait_for_load_i18n);

      Notify.create({
        message: $t('pwa.reload_to_new'),
        // timeout: 0,
        type: 'info',
      });

      setTimeout(() => {
        window.localStorage.setItem('payrafi-app', package_info.version);
        window.location.reload();
      }, 5000);
    }
  }, 500);
}

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  // registrationOptions: { scope: './' },

  ready(/*registration*/) {
    // console.log('Service worker is active.');
  },

  registered(/*registration*/) {
    // console.log('Service worker has been registered.');

    const channel = new BroadcastChannel('push-channel');

    channel.addEventListener('message', () => {
      const new_count =
        parseInt(
          (window.localStorage.getItem('new-push-count') as string) || '0',
        ) + 1;
      window.localStorage.setItem('new-push-count', `${new_count}`);

      if ('setAppBadge' in navigator) {
        (
          navigator as unknown as {
            setAppBadge: (num: number) => void;
          }
        )?.setAppBadge(new_count);
      }
    });
  },

  cached(/* registration */) {
    // window.localStorage.setItem('payrafi-app', package_info.version);
    // console.log('Content has been cached for offline use.');
  },

  updatefound(registration) {
    let wait_for_load_i18n: ReturnType<typeof setInterval> =
      undefined as unknown as ReturnType<typeof setInterval>;
    wait_for_load_i18n = setInterval(() => {
      if ($te('pwa.new_version')) {
        clearInterval(wait_for_load_i18n);
        const stored_ver = window.localStorage.getItem('payrafi-app');
        // If the old version set, it is an update.
        if (stored_ver) {
          Notify.create({
            message: $t('pwa.new_version', {
              old_ver: stored_ver,
              new_ver: package_info.version,
            }),
            timeout: 0,
          });

          const app_updated = setInterval(() => {
            // console.log('registration', registration);
            if (registration && registration?.installing == null) {
              clearInterval(app_updated);

              appUpdated();
            }
          }, 1000);
        } else {
          window.localStorage.setItem('payrafi-app', package_info.version);
        }
      }
    }, 500);
  },

  updated(/* registration */) {
    // console.log('New content is available; please refresh.');
    // appUpdated();
  },

  offline() {
    // console.log('No internet connection found. App is running in offline mode.')
  },

  error(/* err */) {
    // console.error('Error during service worker registration:', err)
  },
});
