import { defineStore } from 'pinia';
import { MetaOptions } from 'quasar/dist/types/meta.d.ts';
import { useMeta } from 'quasar';
import { $t } from 'src/boot/i18n';

export const useMetaTag = defineStore('meta_tag', {
  state: () => {
    return {
      stack: [] as unknown as {
        options: MetaOptions;
        route: string;
      }[],
    };
  },

  actions: {
    set(options: MetaOptions, is_dialog?: boolean) {
      const route_fullPath = (
        this as typeof this & {
          router: { currentRoute: { value: { fullPath: string } } };
        }
      ).router.currentRoute.value.fullPath;

      if (is_dialog)
        this.stack.push({ options: options, route: route_fullPath });
      else this.stack = [{ options: options, route: route_fullPath }];

      options['titleTemplate'] = (title) => `${title} - ${$t('payrafi')}`;
      useMeta(options);
    },

    removeByRoute(route_fullPath?: string) {
      const meta_index = this.stack.findIndex(
        (meta) => meta.route == route_fullPath,
      );
      if (meta_index) this.stack.splice(meta_index, 1);
    },

    restoreLast() {
      // eslint-disable-next-line
      // const route_fullPath = (this as unknown as { router: any }).router
      //   .currentRoute.value.fullPath;

      this.stack.pop();
      const meta = this.stack[this.stack.length - 1];
      if (meta && meta.options) {
        useMeta(meta.options);
      }
    },
  },
});
