import { Dialog } from 'quasar';
import DialogAlert from 'src/components/DialogAlert.vue';
import { $t } from 'src/boot/i18n';

export function canQuickTransfer({
  $auth,
  $router,
}: PolicyRuleExtended<unknown>) {
  if (['Basic', 'Pro'].includes($auth.user.kyc_level)) {
    Dialog.create({
      component: DialogAlert,
      componentProps: {
        title: $t('send'),
        message: $t('acl.send_quick_needs_advance_level'),
        buttons: [
          {
            label: $t('complete_kyc'),
            color: 'primary',
            type: 'dismiss',
            click: () => {
              void $router.push({ name: 'profile.kyc' });
            },
          },
        ],
      },
    });
    return false;
  }

  if ($auth.user.kyc_status == 'Noncompliant') {
    if (!(new Date($auth.user.compliant_expiry_date) > new Date())) {
      Dialog.create({
        component: DialogAlert,
        componentProps: {
          title: $t('time_limit_reached'),
          message: hI18n('time_limit_reached_desc', {
            expiry_date: () =>
              h(
                'span',
                { class: 'text-md text-weight-semibold' },
                formatDate(
                  $auth.user.compliant_expiry_date,
                  'DD/MM/YYYY HH:mm:ss',
                ),
              ),
            compliant_limit: h(
              'span',
              { class: 'text-md text-weight-semibold' },
              $t('x_months', {
                x: $auth.user.compliant_limit,
              }),
            ),
          }),
          btnOk: {
            label: $t('complete_kyc'),
          },
          btnCancel: {
            label: $t('close'),
          },
        },
      }).onOk(() => {
        void $router.push({ name: 'profile.kyc.upgrade' });
      });

      return false;
    }
  }

  return true;
}
