import { defineStore } from 'pinia';
import { MetaOptions } from 'quasar/dist/types/meta.d.ts';
import {
  RouteLocation,
  RouteLocationNormalizedLoadedGeneric,
  useRouter,
} from 'vue-router';
import { QDialog } from 'quasar';
import { useDynamicPage } from './DynamicPage';

export const useSubpage = defineStore('subpage', {
  state: () => {
    return {
      meta_stack: [] as unknown as MetaOptions[],
      routes_stack: [] as RouteLocation[],
      subpages: {} as unknown as {
        [key: string]: {
          route?: RouteLocation;
          ref?: QDialog;
        };
      },
      models: {} as unknown as {
        [key: string]: boolean;
      },
    };
  },

  actions: {
    reset: function () {
      this.subpages = {};
      this.routes_stack = [];
      this.meta_stack = [];
      this.models = {};
    },
    // setModel: function (name: string, value: boolean) {
    //   if (!(name in this.subpages)) this.subpages[name] = {};

    //   this.models[name] = value;
    // },
    show: function (
      name: string,
      to_route: RouteLocation,
      back_to_route: RouteLocation,
      ref: QDialog,
    ) {
      if (!(name in this.subpages)) this.subpages[name] = {};

      this.subpages[name]['route'] = to_route;
      this.subpages[name]['ref'] = ref;
      // = { route: to_route, ref: ref };

      if (this.routes_stack.length < 1) {
        const dynamic_page = useDynamicPage();
        const $route = void (
          this as unknown as {
            router: ReturnType<typeof useRouter>;
          }
        ).router.currentRoute
          .value as unknown as RouteLocationNormalizedLoadedGeneric;

        if (dynamic_page.route && $route?.meta?.component) {
          this.routes_stack.push(dynamic_page.route);
        } else if (
          $route?.meta?.component &&
          $route?.meta?.type &&
          $route?.meta.type != 'dialog'
        ) {
          this.routes_stack.push(to_route);
        } else if (back_to_route) {
          this.routes_stack.push(back_to_route);
        }
      }

      if (to_route.fullPath != this.routes_stack.at(-1)?.fullPath)
        this.routes_stack.push(to_route);
    },
    hide: function (name: string) {
      const subpage = this.get(name);
      if (!subpage || !subpage.route) return;

      const subpage_fullpath = subpage.route.fullPath;

      const subpage_index = this.routes_stack.findIndex((route) => {
        return route.fullPath == subpage_fullpath;
      });

      if (subpage_index != -1) this.routes_stack.splice(subpage_index, 1);

      if (name in this.subpages) delete this.subpages[name];
    },
    has: function (name: string) {
      if (this.subpages[name]) {
        return !!this.subpages[name].ref?.contentEl;
      } else return false;
    },
    hasOneOf: function (names: string[]) {
      names.forEach((name) => {
        if (this.has(name)) return true;
      });

      return false;
    },
    get: function (name: string) {
      return this.subpages[name];
    },
    getAll: function () {
      return this.subpages;
    },
  },
});
