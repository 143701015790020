import dayjs from 'dayjs/esm/index.js';
import { defineStore } from 'pinia';
import { Quasar, Lang } from 'quasar';
import { useAuth } from 'src/stores/Auth';
import { default_language, i18n } from 'src/boot/i18n';

export const useLanguage = defineStore('language', {
  state: () => {
    return {
      locale: i18n.locale as unknown as string,
      loaded: false,
      locale_options: [
        { value: 'en-US', label: 'English', iso: 'US', code: 'en' },
        { value: 'fa-IR', label: 'فارسی', iso: 'IR', code: 'fa' },
      ],
    };
  },
  actions: {
    /*
     * Convert faIR to fa-IR
     * if the reverse is true, convert fa-IR to faIR
     */
    mapLocale(lang: string, reverse?: boolean): string {
      if (reverse == true) return lang.substring(0, 2) + lang.substring(3, 5);
      else return lang.substring(0, 2) + '-' + lang.substring(2, 4);
    },

    setLocale(locale: string) {
      if (locale === undefined) locale = default_language;

      this.loaded = true;
      this.locale = locale;

      const locale_code = locale.split('-')[0];

      const dayjs_langs = import.meta.glob(
        '../../node_modules/dayjs/esm/locale/(fa|en).js',
      );

      void dayjs_langs[
        `../../node_modules/dayjs/esm/locale/${locale_code}.js`
      ]().then(() => {
        dayjs.locale(locale_code);
      });

      // void import(
      //   /* webpackInclude: /(fa|en)\.js$/ */
      //   `dayjs/locale/${locale_code}.js`
      // );

      //
      void import(`../i18n/${locale}.ts`).then(({ default: messages }) => {
        i18n.setLocaleMessage(
          locale,
          messages /* as LocaleMessageDictionary<VueMessageType> */,
        );
        i18n.locale = locale as unknown as typeof i18n.locale;

        const quasar_langs = import.meta.glob(
          '../../node_modules/quasar/lang/(fa-IR|en-US).js',
        );
        void quasar_langs[`../../node_modules/quasar/lang/${locale}.js`]().then(
          (lang) =>
            Lang.set(
              (lang as { default: Parameters<(typeof Lang)['set']>[0] })
                ?.default,
            ),
        );
      });
    },

    loadDefaultLocale() {
      const browser = Quasar.lang.getLocale() as string;

      if (['fa-IR', 'en-US'].includes(browser)) {
        // setLocale(browser);
        this.setLocale(default_language);
      } else {
        this.setLocale(default_language);
      }
    },

    async updateLocale(locale: string) {
      this.setLocale(locale);
      const $auth = useAuth();
      const new_locale = this.mapLocale(locale, true);
      $auth.user.locale = new_locale;
      void updateProfileLocale(new_locale);
    },
  },
  getters: {
    current_language() {
      const res = this.locale_options.find(
        (locale) => locale.value == this.locale,
      ) as unknown as { label: string };

      return res.label;
    },
  },
});
