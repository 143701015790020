import { Dialog } from 'quasar';
import DialogAlert from 'src/components/DialogAlert.vue';
import { $t } from 'src/boot/i18n';

export function canBecomePartner({
  $auth,
  $router,
}: PolicyRuleExtended<object>) {
  if (
    $auth.user.kyc_level != 'Advance' ||
    ($auth.user.kyc_level == 'Advance' &&
      $auth.user.kyc_status == KYCStatus.Noncompliant)
  ) {
    Dialog.create({
      component: DialogAlert,
      componentProps: {
        title: $t('attention'),
        message: $t('acl.become_partner_needs_advance'),
        buttons: [
          {
            label: $t('complete_kyc'),
            color: 'primary',
            type: 'dismiss',
            click: () => {
              void $router.push({ name: 'profile.kyc' });
            },
          },
        ],
      },
    });
    return false;
  }

  return true;
}
