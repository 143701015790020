<template>
  <q-dialog
    ref="dialogRef"
    no-focus
    class="my-dialog"
    persistent
    @hide="onDialogHide"
  >
    <q-card class="no-shadow">
      <q-card-section class="q-mb-lg" :class="{ 'q-mt-lg': !hasCloseBtn }">
        <div v-if="hasCloseBtn" class="row items-center q-pb-none">
          <q-space />
          <q-btn v-close-popup :icon="icons.close" flat round dense />
        </div>
        <div class="text-lg text-weight-bold text-dark q-mb-sm text-center">
          {{ title }}
        </div>
        <div
          class="text-sm text-weight-semibold text-my-grey render-lines"
          :class="[`text-${bodyAlign}`]"
          style="white-space: break-spaces"
        >
          <component :is="message" v-if="typeof message == 'object'" />
          <template v-else>{{ message }}</template>
        </div>

        <slot />
      </q-card-section>
      <q-separator />

      <q-card-actions v-if="buttons == null" align="center">
        <div class="text-center" style="width: calc(50% - 5px)">
          <q-btn
            class="text-md text-weight-bold full-width"
            no-caps
            flat
            :label="btnCancel?.label ?? $t('cancel')"
            data-autofocus
            color="my-grey"
            @click="onDialogCancel"
          />
        </div>

        <q-separator style="margin: 0 4px" vertical inset />

        <div class="text-center" style="width: calc(50% - 5px)">
          <q-btn
            class="text-md text-weight-bold full-width"
            no-caps
            flat
            :label="btnOk?.label ?? $t('confirm')"
            :color="btnOk?.color ?? 'primary'"
            @click="onDialogOK"
          />
        </div>
      </q-card-actions>

      <q-card-actions v-else-if="buttons.length > 0" align="center">
        <template v-for="(button, index) in buttons" :key="index">
          <div
            class="text-center q-px-xs"
            :style="{
              width: `calc((100% / ${buttons.length}) - ${
                buttons.length - 1
              }px * 5)`,
            }"
          >
            <q-btn
              class="text-md text-weight-bold full-width"
              no-caps
              flat
              :label="button.label"
              :color="button?.color ?? 'primary'"
              :tabindex="button?.color != 'primary' ? -1 : undefined"
              @click="() => clickButton(button)"
            />
          </div>
          <q-separator
            v-if="index < buttons.length - 1"
            style="margin: 0 4px"
            vertical
            inset
          />
        </template>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script lang="ts">
import { QDialog, useDialogPluginComponent } from 'quasar';
import { DialogAlertButton } from 'src/types';
import { roundClose } from '@quasar/extras/material-icons-round';

export default defineComponent({
  name: 'DialogAlert',
  props: {
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    caption: {
      type: String,
      required: false,
      default: undefined,
    },
    message: {
      type: Object as PropType<ReturnType<typeof h> | string>,
      required: false,
      default: undefined,
    },
    btnOk: {
      type: Object,
      default: undefined,
    },
    btnCancel: {
      type: Object,
      default: undefined,
    },
    buttons: {
      type: Array as PropType<DialogAlertButton[]>,
      default: undefined,
    },
    closeBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    bodyAlign: {
      type: String,
      required: false,
      default: 'center',
    },
  },
  emits: [...useDialogPluginComponent.emits],

  setup() {
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();

    return {
      icons: {
        close: roundClose,
      },
      dialogRef,
      onDialogHide,
      onDialogOK,
      onDialogCancel,
    };
  },

  computed: {
    hasCloseBtn() {
      return (
        this.closeBtn && !(this.buttons == null || this.buttons == undefined)
      );
    },
  },
  expose: ['hide'],
  methods: {
    hide() {
      (this.$refs.dialogRef as QDialog).hide();
    },
    clickButton(button: DialogAlertButton) {
      if (button?.click) {
        button.click();
      }
      switch (button?.type) {
        case 'ok':
          this.onDialogOK();
          break;
        case 'cancel':
          this.onDialogCancel();
          break;
        default:
          this.hide();
          break;
      }
    },
  },
});
</script>

<style scoped>
.my-dialog {
  .q-dialog__inner > div {
    border-radius: 8px;
    width: 468px;
    max-width: calc(100vw - 16px);
  }
}
</style>
