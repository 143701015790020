export const KYCRequestRequirementType = {
  IdCard: 'IdCard',
  Selfie: 'Selfie',
  Questionnaire: 'Questionnaire',
} as const;

export const KYCRequestRequirementStatus = {
  Pending: 'Pending',
  Upload: 'Upload',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
} as const;

export const KYCRequestStatus = {
  Open: 'Open',
  Processing: 'Processing',
  Rejected: 'Rejected',
  Close: 'Close',
} as const;

export const PartnershipStatus = {
  Pending: 'Pending',
  Processing: 'Processing',
  Failed: 'Failed',
  Verified: 'Verified',
  Cancelled: 'Cancelled',
} as const;

export const PartnerBranch = {
  Wire: 'Wire',
  Cdm: 'Cdm',
  Paynow: 'Paynow',
  Cash: 'Cash',
} as const;

export const OrderGateway = {
  Cash: 'Cash',
  Wire: 'Wire',
  Cdm: 'Cdm',
  Paynow: 'Paynow',
  PayrafiTronGateway: 'PayrafiTronGateway',
  Convert: 'Convert',
  Move: 'Move',
} as const;

export const SubOrderStatus = {
  Pending: 'Pending',
  Done: 'Done',
};

export const OrderStatus = {
  Submitted: 'Submitted',
  Pending: 'Pending',
  Canceled: 'Canceled',
  Done: 'Done',
  SystemProcessing: 'SystemProcessing',
  WaitingForRecipientAccept: 'WaitingForRecipientAccept',
  DeclinedByRecipient: 'DeclinedByRecipient',
  SearchingForPartner: 'SearchingForPartner',
  HasOffer: 'HasOffer',
  PartnerNotFound: 'PartnerNotFound',
  WaitingForMoneyReceiptConfirmation: 'WaitingForMoneyReceiptConfirmation',
  WaitingForPartnerCancellationApproval:
    'WaitingForPartnerCancellationApproval',
  OrderFundNotConfirmedInSpecifiedTime: 'OrderFundNotConfirmedInSpecifiedTime',
  DepositOrderRejectedByPartner: 'DepositOrderRejectedByPartner',
  InCompleteSendTransferWithSpecifiedTime:
    'InCompleteSendTransferWithSpecifiedTime',
  SendOrderDisputedByCustomer: 'SendOrderDisputedByCustomer',
  WaitingForQtDeposit: 'WaitingForQtDeposit',
  WaitingForQtSend: 'WaitingForQtSend',
} as const;

export const OrderType = {
  Add: 'Add',
  Send: 'Send',
  Convert: 'Convert',
  Quick: 'Quick',
} as const;

export const BranchType = {
  Cash: 'Cash',
  Wire: 'Wire',
  Cdm: 'Cdm',
  Paynow: 'Paynow',
} as const;

export const TransferType = {
  Normal: 'Normal',
  Quick: 'Quick',
} as const;

export const KYCStatus = {
  Pending: 'Pending',
  Processing: 'Processing',
  Verified: 'Verified',
  Noncompliant: 'Noncompliant',
} as const;

export const KYCLevel = {
  Basic: 'Basic',
  Pro: 'Pro',
  Advance: 'Advance',
} as const;

export const CaptchaType = {
  Cloudflare: 'Cloudflare',
  Image: 'Image',
} as const;
