<template>
  <router-view />
</template>
<script lang="ts">
// import package_info from '../package.json';
// import DialogAlert from './components/DialogAlert.vue';
import { captureException } from '@sentry/vue';

export default defineComponent({
  name: 'App',
  created() {
    // if (process.env.MODE == 'pwa') {
    //   if ('serviceWorker' in navigator) {
    //     void navigator.serviceWorker
    //       .getRegistrations()
    //       .then(async function (registrations) {
    //         if (registrations.length > 0) {
    //           for (let registration of registrations) {
    //             if (
    //               registration?.active &&
    //               registration?.active?.scriptURL &&
    //               registration?.active?.scriptURL?.indexOf('sw.js') > -1
    //             )
    //               void registration.update();
    //           }
    //         }
    //       });
    //   }
    // }

    const checker = document.getElementById('network_check');
    if (checker) {
      checker.style.display = 'none';
      checker.remove();
    }
    clearTimeout(document.network_check);

    // eslint-disable-next-line
    let that = this;

    window.addEventListener('unhandledrejection', (evt) => {
      evt.preventDefault();

      // eslint-disable-next-line
      return that.handleError(evt.reason);
    });

    window.addEventListener('focus', () => {
      if ('setAppBadge' in navigator) {
        (
          navigator as unknown as {
            clearAppBadge: () => void;
          }
        ).clearAppBadge();
      }
      window.localStorage.setItem('new-push-count', '0');
    });

    // eslint-disable-next-line
    onErrorCaptured((e: any) => {
      // eslint-disable-next-line
      return that.handleError(e);
    });
  },
  methods: {
    handleError(e: {
      name: string;
      response?: { data: { Msg: string }; status: number };
      message: string;
      options: AppErrorOptions;
    }) {
      // eslint-disable-next-line
      let msg = e?.response?.data?.Msg || e.message;

      if (e?.response?.status == 401) {
        this.$auth.cleanSession();
        void (async () => {
          await this.$router.push({
            name: 'login',
            query: { redirect: this.$route.fullPath },
          });
          this.$auth.cleanState();
        })();
        msg = this.$t('you_logged_out');
      }

      // Ignore these:
      // #1 => Map: Expected mapDiv of type HTMLElement but was passed null.
      // eslint-disable-next-line
      if ((msg as string)?.indexOf('mapDiv of type HTMLElement') >= 0)
        return false;

      // signalR handshake error
      if ((msg as string)?.indexOf('Server returned handshake error') >= 0)
        return false;

      if (
        /* // Webpack
        msg.indexOf('Loading chunk') >= 0 ||
        msg.indexOf('Loading CSS chunk') >= 0
        */

        // Vite
        msg?.indexOf('Failed to fetch dynamically imported module') >= 0 ||
        msg?.indexOf('Unable to preload CSS') >= 0 ||
        msg?.indexOf('not a valid JavaScript MIME type') >= 0
      ) {
        this.$q.notify({
          message: this.$t('pwa.failed_load_chunk'),
          timeout: 0,
          classes: 'update-notify bg-warning text-black',
          actions: [
            {
              label: this.$t('reload'),
              handler: () => {
                // This decleard on index.html
                const my_window = window as typeof window & {
                  refresh: () => void;
                };
                if (
                  my_window?.refresh &&
                  typeof my_window?.refresh === 'function'
                ) {
                  my_window.refresh();
                } else {
                  window.location.reload();
                }
              },
            },
          ],
        });
      } /* else if (e?.name == 'TypeError') {
        this.$q.notify({
          type: 'negative',
          message: this.$t('error.type_error'),
          actions: e?.options?.actions,
        });
      } */ else if (msg == 'Network Error') {
        this.$q.notify({
          type: 'warning',
          message: this.$t('error.network_error'),
          actions: e?.options?.actions,
        });
      } else if (msg == 'Request aborted') {
        // Axios things
        // While calling an API the use may refresh the page and get this error
        // We can skip it
        console.log('Request aborted ...');
      } /* else if (e?.name == 'SyntaxError') {
        // This may occur when updating PWA
        // We skip it for now and reload the page
        console.log('Updating App ...');

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } */ else {
        const unhandled_error =
          msg?.indexOf('error.') === 0 ||
          msg?.indexOf('API Error =>') === 0 ||
          e?.name == 'AxiosError'; /* && !!e?.response?.data?.Msg */

        const error_message =
          !process.env.DEBUGGING && unhandled_error
            ? this.$t('error.unhandled_error')
            : msg;

        this.$q.notify({
          type: 'negative',
          message: error_message,
          actions: e?.options?.actions,
        });

        const debug_mode = useStorage('debug_mode', false);

        if ((process.env.DEBUGGING || debug_mode.value) && unhandled_error) {
          console.error('Error Handle => ', e);
        }

        if (!process.env.DEBUGGING && unhandled_error) captureException(e);
      }

      return false;
    },
  },
});
</script>

<style>
.q-dialog__backdrop {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}

.q-loading__backdrop {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
}
</style>
