import { boot } from 'quasar/wrappers';
import { createI18n } from 'vue-i18n';
import messages from 'src/i18n/en-US';

export type MessageLanguages = keyof typeof messages;
// Type-define 'en-US' as the master schema for the resource
export type MessageSchema = typeof messages;

// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
/* eslint-disable */
declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {}

  // define the datetime format schema
  export interface DefineDateTimeFormat {}

  // define the number format schema
  export interface DefineNumberFormat {}
}
/* eslint-enable */

const default_language = 'en-US';

/* eslint-disable */
const i18n_obj = createI18n({
  locale: default_language,
  legacy: false,
});
/* eslint-enable */

const i18n = i18n_obj.global;

const $t = i18n['t'];
const $te = i18n['te'];

export default boot(({ app }) => {
  // Set i18n instance on app
  app.use(i18n_obj);

  const $auth = useAuth();
  const manager = useLanguage();

  if ($auth.token == undefined || $auth.token == null) {
    manager.loadDefaultLocale();
  }
});
export { i18n, $t, $te, default_language };
