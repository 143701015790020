import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/LeanLayout.vue'),
    meta: { area: 'guest', requiresGuest: true },
    children: [
      {
        name: 'intro',
        path: '/',
        component: () => import('pages/IntroShow.vue'),
      },
      {
        name: 'login',
        path: '/login',
        component: () => import('pages/LoginShow.vue'),
      },
      // {
      //   name: 'exchange-rates',
      //   path: '/exchange-rates',
      //   component: () => import('pages/RatesGuestShow.vue'),
      // },
      {
        name: 'register',
        path: '/register',
        component: () => import('pages/RegisterShow.vue'),
      },
      {
        name: 'forget-password',
        path: '/forget-password',
        component: () => import('pages/ForgetPasswordShow.vue'),
      },
    ],
  },

  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        component: () => import('layouts/ClientLayout.vue'),
        meta: { requiresAuth: true, area: 'client' },
        children: [
          {
            name: 'home',
            path: '/home',
            meta: { component: 'HomeShow' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'wallets.create',
            path: '/wallets/create',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'notifications',
            path: '/notifications',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'become-partner',
            path: '/become-partner',
            meta: { component: 'HomeShow', type: 'dialog' },

            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'profile.edit',
            path: '/profile/edit',
            meta: { component: 'HomeShow', type: 'dialog' },

            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'profile.kyc',
            path: '/profile/kyc',
            meta: { component: 'HomeShow', type: 'dialog' },

            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'profile.kyc.upgrade',
            path: '/profile/kyc/upgrade',
            meta: { component: 'HomeShow', type: 'dialog' },

            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'profile.kyc.edit',
            path: '/profile/kyc/edit',
            meta: { component: 'HomeShow', type: 'dialog' },

            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'profile.show',
            path: '/profile',
            meta: { component: 'HomeShow', type: 'dialog' },

            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'profile.security',
            path: '/profile/security',
            meta: { component: 'HomeShow', type: 'dialog' },

            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'profile.security.change-password',
            path: '/profile/security/change-password',
            meta: { component: 'HomeShow', type: 'dialog' },

            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'profile.kyc',
            path: '/profile/kyc',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'wallets.show',
            path: '/wallets/:wallet_code',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'wallets.transactions.show',
            path: '/wallets/:wallet_code/transactions',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'wallets.deposit-money',
            path: '/wallets/:wallet_code/deposit-money',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'wallets.convert-money',
            path: '/wallets/:wallet_code/convert-money',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'wallets.send-money',
            path: '/wallets/:wallet_code/send-money',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'quick-transfer',
            path: '/quick-transfer',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'orders.index',
            path: '/orders',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },

          {
            name: 'orders.show',
            path: '/orders/:order_code',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },

          {
            name: 'orders.deposit-payments.index',
            path: '/orders/:order_code/deposit-payments',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'orders.send-payments.index',
            path: '/orders/:order_code/send-payments',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },

          {
            name: 'orders.deposit-payments.create',
            path: '/orders/:order_code/deposit-payments/create',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'orders.send-payments.create',
            path: '/orders/:order_code/send-payments/create',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'orders.deposit-payments.edit',
            path: '/orders/:order_code/deposit-payments/:payment_id/edit',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'orders.send-payments.edit',
            path: '/orders/:order_code/send-payments/:payment_id/edit',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'orders.deposit-payments.show',
            path: '/orders/:order_code/deposit-payments/:payment_id',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'orders.send-payments.show',
            path: '/orders/:order_code/send-payments/:payment_id',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'receipts.show',
            path: '/receipts/:order_code',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'more',
            path: '/more',
            meta: { component: 'MoreShow' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'rates',
            path: '/rates',
            meta: { component: 'RatesShow' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'all-rates',
            path: '/rates/all',
            meta: { component: 'RatesShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },

          {
            name: 'recipients.index',
            path: '/recipients',
            meta: { component: 'RecipientsIndex' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'recipients.edit',
            path: '/recipients/:recipient_id/edit',
            meta: { component: 'RecipientsIndex', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'recipients.show',
            path: '/recipients/:recipient_id',
            meta: { component: 'RecipientsIndex', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'recipients.add-info',
            path: '/recipients/:recipient_id/add-info',
            meta: { component: 'RecipientsIndex', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'recipients.show-info',
            path: '/recipients/:recipient_id/infos/:info_id',
            meta: { component: 'RecipientsIndex', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'recipients.edit-info',
            path: '/recipients/:recipient_id/edit-info',
            meta: { component: 'RecipientsIndex', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'recipients.create',
            path: '/recipients/create',
            meta: { component: 'RecipientsIndex', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
        ],
      },
      {
        path: '/',
        meta: { requiresAuth: true, area: 'partner' },
        component: () => import('layouts/PartnerLayout.vue'),
        children: [
          {
            name: 'partner.profile.show',
            path: '/profile',
            meta: { component: 'PartnerHomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.notifications',
            path: '/notifications',
            meta: { component: 'PartnerHomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.home',
            path: '/partner',
            meta: { component: 'PartnerHomeShow' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.become-partner-again',
            path: '/become-partner',
            meta: { component: 'PartnerHomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          // {
          //   name: 'partner.incoming-orders',
          //   path: '/partner/incoming-orders',
          //   meta: { component: 'PartnerOrders' },
          //   component: () => import('src/components/DynamicPage.vue'),
          // },
          {
            name: 'partner.orders.index',
            path: '/partner/orders',
            meta: { component: 'PartnerHomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.orders.show',
            path: '/partner/orders/:order_code',
            meta: { component: 'PartnerHomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.orders.show-recipient',
            path: '/partner/orders/:order_code/recipient',
            meta: { component: 'PartnerHomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },

          // {
          //   name: 'partner.orders.payments-review.index',
          //   path: '/partner/orders/:order_code/payments-review',
          //   meta: { component: 'PartnerHomeShow', type: 'dialog' },
          //   component: () => import('src/components/DynamicPage.vue'),
          // },
          // {
          //   name: 'partner.orders.payments-review.show',
          //   path: '/partner/orders/:order_code/payments-review/:payment_id',
          //   meta: { component: 'PartnerHomeShow', type: 'dialog' },
          //   component: () => import('src/components/DynamicPage.vue'),
          // },
          {
            name: 'partner.orders.deposit-payments.index',
            path: '/partner/orders/:order_code/deposit-payments',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.orders.send-payments.index',
            path: '/partner/orders/:order_code/send-payments',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.orders.deposit-payments.create',
            path: '/partner/orders/:order_code/deposit-payments/create',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.orders.send-payments.create',
            path: '/partner/orders/:order_code/send-payments/create',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.orders.deposit-payments.edit',
            path: '/partner/orders/:order_code/deposit-payments/:payment_id/edit',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.orders.send-payments.edit',
            path: '/partner/orders/:order_code/send-payments/:payment_id/edit',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.orders.deposit-payments.show',
            path: '/partner/orders/:order_code/deposit-payments/:payment_id',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.orders.send-payments.show',
            path: '/partner/orders/:order_code/send-payments/:payment_id',
            meta: { component: 'HomeShow', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },

          {
            name: 'partner.branches.index',
            path: '/partner/branches',
            meta: { component: 'BranchesIndex' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.branches.create',
            path: '/partner/branches/create',
            meta: { component: 'BranchesIndex', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.branches.edit',
            path: '/partner/branches/:branch_id/edit',
            meta: { component: 'BranchesIndex', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.branches.show',
            path: '/partner/branches/:branch_id',
            meta: { component: 'BranchesIndex', type: 'dialog' },
            component: () => import('src/components/DynamicPage.vue'),
          },
          {
            name: 'partner.more',
            path: '/partner/more',
            meta: { component: 'MoreShow' },
            component: () => import('src/components/DynamicPage.vue'),
          },
        ],
      },
    ],
  },

  {
    name: 'logout',
    path: '/logout',
    meta: { requiresAuth: true },
    component: () => import('pages/LogoutShow.vue'),
  },
  {
    path: '/',
    component: () => import('layouts/GuestLayout.vue'),
    meta: { area: 'guest', requiresGuest: true },
    children: [
      {
        name: 'exchange-rates',
        path: '/exchange-rates',
        component: () => import('pages/RatesGuestShow.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404Show.vue'),
  },
];

export default routes;
