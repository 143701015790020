// import { getScrollWatcher } from './../helpers/index';
import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import { LoadingBar } from 'quasar';
// import { useAuth } from 'src/stores/Auth';
// import { useLanguage } from 'src/stores/Language';
// import { LOADINGBAR_DEFAULT } from 'src/helpers/LoadingHelper';
import routes from './routes';

// export function fadeOut(selector: string, timeInterval: number) {
//   const fadeTarget: HTMLElement = document.querySelector(
//     selector,
//   ) as HTMLElement;
//   if (fadeTarget) {
//     const time = timeInterval / 1000;
//     fadeTarget.style.transition = `${time}` + 's';
//     fadeTarget.style.opacity = '0';
//     const fadeEffect = setInterval(function () {
//       if (fadeTarget.style.opacity <= '0') {
//         clearInterval(fadeEffect);
//         fadeTarget.remove();
//       }
//     }, timeInterval);
//   }
// }

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

let splash = true;
let need_loading = false;
let is_loading = false;

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.afterEach(() => {
    // Debounce
    if (need_loading) {
      need_loading = false;
    }
    if (is_loading) {
      LoadingBar.stop();
    }

    if (splash == true) {
      splash = false;
      window.fadeOut('#splash-loading', 500);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Router.beforeEach(async (to, from) => {
    let vars;
    if (to.meta.area == 'partner') {
      vars = { ...LOADINGBAR_DEFAULT, color: 'primary' };
    } else {
      vars = { ...LOADINGBAR_DEFAULT, color: 'info' };
    }
    LoadingBar.setDefaults(vars);

    // Debounce
    need_loading = true;
    setTimeout(() => {
      if (need_loading) {
        is_loading = true;
        LoadingBar.start();
      }
    }, 500);

    // if (to.meta.component && to.meta.component != from.meta.component) {
    //   let need_loading = true;
    //   setTimeout(() => {
    //     if (need_loading) {
    //       LoadingBar.start(300);
    //     }
    //   }, 500);

    //   await import(
    //     `src/pages/${to.meta.component as string}.vue`
    //   );

    //   if (need_loading && LoadingBar.isActive) {
    //     need_loading = false;
    //     setTimeout(() => {
    //       LoadingBar.stop();
    //     }, 250);
    //   } else {
    //     need_loading = false;
    //   }
    // }
  });

  Router.beforeEach(async (to, from, next) => {
    const requiresGuest = to.matched.some(
      (record) => record.meta.requiresGuest,
    );
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresGuest || requiresAuth) {
      const $auth = useAuth();

      const is_login = await $auth.isLogin();

      const manager = useLanguage();
      if (manager.loaded == false) {
        manager.loadDefaultLocale();
      }

      if (requiresGuest) {
        if (is_login) {
          next({
            name: 'home',
          });
        } else {
          next();
        }
      }

      if (requiresAuth) {
        if (!is_login) {
          next({
            name: 'login',
            query: { redirect: to.fullPath != '/logout' ? to.fullPath : '/' },
          });
        } else {
          next();
        }
      }
    } else {
      const manager = useLanguage();
      if (manager.loaded == false) {
        manager.loadDefaultLocale();
      }

      next();
    }
  });

  return Router;
});
