import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';

export const useDynamicPage = defineStore('dynamic_page', {
  state: () => {
    const route = useRoute();
    // const router = useRouter();
    return {
      component: (route?.meta?.component
        ? route?.meta?.component
        : undefined) as string | undefined,
      route: route?.meta?.type != 'dialog' ? route : undefined,
      // scroll_flag: false,
    };
  },

  actions: {},
});
