import dayjsESM from 'dayjs/esm/index.js';
import RelativeTime from 'dayjs/esm/plugin/relativeTime';
import { date } from 'quasar';

dayjsESM.extend(RelativeTime);

export const formatDate = date.formatDate;
export const dayjs = dayjsESM;

export function toSnakeCase(pascal_case_string: string) {
  return pascal_case_string
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
}

export function truncate(str: string, max?: number, sep?: string) {
  max = max || 10;
  const len = str.length;
  if (len > max) {
    sep = sep || '...';
    const seplen = sep.length;
    if (seplen > max) {
      return str.substring(len - max);
    }
    const n = -0.5 * (max - len - seplen);
    const center = len / 2;
    return str.substring(0, center - n) + sep + str.substring(len - center + n);
  }
  return str;
}
export function formatPrice(
  value: number | string,
  currency: string,
  suffix = true,
  options?: {
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    smartFractionDigits?: boolean;
  },
): string {
  let result = '';
  const val = typeof value == 'string' ? Number(value) : value;

  if (typeof currency != 'string') {
    throw new Error('wrong_currency_iso_code');
  }

  result += formatNumber(val, options);

  if (suffix) return currency + ' ' + result;

  return result;
}

function countLeadingZerosAfterDecimal(num: number) {
  if (num >= 1 || !num) return 0;

  const numStr = num.toString().substring(2);

  const firstNonZeroIndex = numStr.indexOf(numStr.match(/[1-9]/)?.[0] || '');

  return firstNonZeroIndex === -1 ? 0 : firstNonZeroIndex;
}
export function formatCardNumber(text?: string) {
  return text?.replace(/(\d{4})(?=\d)/g, '$1 ');
}

export function formatNumber(
  number: number,
  options?: {
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    smartFractionDigits?: boolean;
  },
) {
  if (number == 0) number = 0;

  if (!options) {
    options = {};
  }

  if (!options?.['minimumFractionDigits']) options['minimumFractionDigits'] = 2;

  if (!options?.['maximumFractionDigits'])
    options['maximumFractionDigits'] = 20;

  if (options?.smartFractionDigits) {
    const leading_zeros = countLeadingZerosAfterDecimal(number);

    const smartFractionDigits = leading_zeros > 2 ? leading_zeros + 1 : 2;

    options['maximumFractionDigits'] = smartFractionDigits;
  }

  return Number(number).toLocaleString('en', {
    ...options,
    // roundingMode: 'trunc',
  } as unknown as Intl.NumberFormatOptions);
}
