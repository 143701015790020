const LOADINGBAR_DEFAULT = {
  color: 'info',
  size: '5px',
  hijackFilter(url: string) {
    return (
      !/cdn-cgi/.test(url) &&
      !/CountUnread/.test(url) &&
      !/GetIncomingOrders/.test(url)
    );
  },
};

export { LOADINGBAR_DEFAULT };
