import { Dialog } from 'quasar';
import DialogAlert from 'src/components/DialogAlert.vue';
import { $t } from 'src/boot/i18n';

export function canWalletSendMoney({
  $arguments,
  $auth,
  $router,
}: PolicyRuleExtended<{
  $arguments: {
    currency_iso_code: string;
  };
}>) {
  if ($auth.user.kyc_level == 'Basic') {
    Dialog.create({
      component: DialogAlert,
      componentProps: {
        title: $t('send'),
        message: $t(
          $arguments.currency_iso_code == 'USDT'
            ? 'acl.send_usdt_needs_pro_level'
            : 'acl.send_basic_needs_advance_level',
        ),
        buttons: [
          {
            label: $t('complete_kyc'),
            color: 'primary',
            type: 'dismiss',
            click: () => {
              void $router.push({ name: 'profile.kyc' });
            },
          },
        ],
      },
    });
    return false;
  }

  if ($arguments.currency_iso_code != 'USDT' && $auth.user.kyc_level == 'Pro') {
    Dialog.create({
      component: DialogAlert,
      componentProps: {
        title: $t('send'),
        message: $t('acl.send_pro_needs_advance_level'),
        buttons: [
          {
            label: $t('send_usdt'),
            color: 'my-grey',
            type: 'dismiss',
            click: () => {
              const usdt_wallet = $auth.wallets.find(
                (wallet) => wallet.currency_iso_code == 'USDT',
              ) as unknown as WalletsModel[0];
              void $router.push({
                name: 'wallets.send-money',
                params: { wallet_code: usdt_wallet.code },
              });
              //
            },
          },
          {
            label: $t('complete_kyc'),
            color: 'primary',
            type: 'dismiss',
            click: () => {
              void $router.push({ name: 'profile.kyc' });
            },
          },
        ],
      },
    });
    return false;
  }

  if ($auth.user.kyc_status == 'Noncompliant') {
    if (!(new Date($auth.user.compliant_expiry_date) > new Date())) {
      Dialog.create({
        component: DialogAlert,
        componentProps: {
          title: $t('time_limit_reached'),
          message: hI18n('time_limit_reached_desc', {
            expiry_date: () =>
              h(
                'span',
                { class: 'text-md text-weight-semibold' },
                formatDate(
                  $auth.user.compliant_expiry_date,
                  'DD/MM/YYYY HH:mm:ss',
                ),
              ),
            compliant_limit: h(
              'span',
              { class: 'text-md text-weight-semibold' },
              $t('x_months', {
                x: $auth.user.compliant_limit,
              }),
            ),
          }),
          btnOk: {
            label: $t('complete_kyc'),
          },
          btnCancel: {
            label: $t('close'),
          },
        },
      }).onOk(() => {
        void $router.push({ name: 'profile.kyc.upgrade' });
      });

      return false;
    }
  }

  return true;
}

export function canWalletConvertMoney({
  $arguments,
  $auth,
  $router,
}: PolicyRuleExtended<{
  $arguments: {
    currency_iso_code: string;
  };
}>) {
  if ($auth.user.kyc_level == 'Basic') {
    Dialog.create({
      component: DialogAlert,
      componentProps: {
        title: $t('convert'),
        message: $t(
          $arguments.currency_iso_code == 'USDT'
            ? 'acl.convert_usdt_needs_pro_level'
            : 'acl.convert_basic_needs_advance_level',
        ),
        buttons: [
          {
            label: $t('complete_kyc'),
            color: 'primary',
            type: 'dismiss',
            click: () => {
              void $router.push({ name: 'profile.kyc' });
            },
          },
        ],
      },
    });
    return false;
  }

  if (
    $auth.user.kyc_level == 'Pro' &&
    $arguments!['currency_iso_code'] != 'USDT'
  ) {
    Dialog.create({
      component: DialogAlert,
      componentProps: {
        title: $t('convert'),
        message: $t('acl.convert_pro_needs_advance_level'),
        buttons: [
          {
            label: $t('send_usdt'),
            color: 'my-grey',
            type: 'dismiss',
            click: () => {
              const usdt_wallet = $auth.wallets.find(
                (wallet) => wallet.currency_iso_code == 'USDT',
              ) as unknown as WalletsModel[0];
              void $router.push({
                name: 'wallets.convert-money',
                params: { wallet_code: usdt_wallet.code },
              });
              //
            },
          },
          {
            label: $t('complete_kyc'),
            color: 'primary',
            type: 'dismiss',
            click: () => {
              void $router.push({ name: 'profile.kyc' });
            },
          },
        ],
      },
    });
    return false;
  }

  return true;
}

export function canWalletAddMoney({
  $arguments,
  $auth,
  $router,
}: PolicyRuleExtended<{
  $arguments: {
    currency_iso_code: string;
  };
}>) {
  if ($auth.user.kyc_level == 'Basic') {
    Dialog.create({
      component: DialogAlert,
      componentProps: {
        title: $t('deposit'),
        message: $t(
          $arguments.currency_iso_code == 'USDT'
            ? 'acl.deposit_usdt_needs_pro_level'
            : 'acl.deposit_basic_needs_advance_level',
        ),
        buttons: [
          {
            label: $t('complete_kyc'),
            color: 'primary',
            type: 'dismiss',
            click: () => {
              void $router.push({ name: 'profile.kyc' });
            },
          },
        ],
      },
    });
    return false;
  }

  if (
    $auth.user.kyc_level == 'Pro' &&
    $arguments!['currency_iso_code'] != 'USDT'
  ) {
    Dialog.create({
      component: DialogAlert,
      componentProps: {
        title: $t('deposit'),
        message: $t('acl.deposit_pro_needs_advance_level'),
        buttons: [
          {
            label: $t('send_usdt'),
            color: 'my-grey',
            type: 'dismiss',
            click: () => {
              const usdt_wallet = $auth.wallets.find(
                (wallet) => wallet.currency_iso_code == 'USDT',
              ) as unknown as WalletsModel[0];
              void $router.push({
                name: 'wallets.deposit-money',
                params: { wallet_code: usdt_wallet.code },
              });
              //
            },
          },
          {
            label: $t('complete_kyc'),
            color: 'primary',
            type: 'dismiss',
            click: () => {
              void $router.push({ name: 'profile.kyc' });
            },
          },
        ],
      },
    });
    return false;
  }

  return true;
}

export function canWalletCreate(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params: Parameters<PolicyRule>,
) {
  return true;
}
