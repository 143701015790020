import { api } from 'boot/axios';
import { AppCaptchaValueModel } from 'src/types/models';
import { APIResponse } from 'src/types/responses';
import {
  GetWalletsInsightResponse,
  UploadAvatarResponse,
  GetAccountInsightResponse,
  GetNotificationListResponse,
  GetProfileResponse,
  GetUnreadNotifCountResponse,
  SignInRequestResponse,
  SignInResponse,
  GetOpenKycResponse,
  SignUpResponse,
  GetGlobalTermsResponse,
  VerifyIDNumberResponse,
} from 'src/types/responses';
import { getAvatarUrl, handleError } from 'src/helpers';

export async function updatePersonalInfo(user: {
  // first_name: string;
  // last_name: string;
  // company_name: string;
  // is_company: boolean;
  gender: string;
  birth_date: string;
  id_card_number: string;
}) {
  const json = {
    // firstName: user.first_name,
    // lastName: user.last_name,
    // companyName: user.company_name,
    // isCompany: user.is_company,
    companyName: undefined,
    isCompany: false,
    gender: user.gender,
    birthDate: user.birth_date,
    idCardNumber: user.id_card_number,
  };
  const { data } = await api.put<APIResponse>(
    '/api/user/UserProfile/EditPersonalInfo',
    json,
  );

  handleError(data.header);

  return;
}

export async function updateAddressInfo(params: {
  country_id: number;
  state: string;
  city: string;
  address1: string;
  address2: string;
  postal_code: string;
}) {
  const { data } = await api.put<APIResponse>(
    '/api/user/UserProfile/EditAddressInfo',
    {
      countryId: params.country_id,
      city: params.state,
      state: params.city,
      address1: params.address1,
      address2: params.address2,
      postCode: params.postal_code,
    },
  );

  handleError(data.header);

  return;
}

export async function requestVerifyMobile(
  dial_prefix: string,
  phone_number: string,
) {
  const { data } = await api.post<APIResponse>(
    '/api/user/UserProfile/PhoneVerification',
    {},
    {
      params: {
        rawPhoneNumber: phone_number,
        dialPrefix: dial_prefix,
      },
    },
  );

  handleError(data.header);
}

export async function validateMobileOTP(phone_number: string, code: string) {
  const { data } = await api.post<APIResponse>(
    '/api/user/UserProfile/PhoneVerificationCodeValidation',
    null,
    {
      params: {
        code: code,
        phoneNumber: phone_number,
      },
    },
  );

  handleError(data.header);
}

export async function resendLoginOTP(token: string) {
  const { data } = await api.post<APIResponse>('/api/RequestNewSignInOtp', {
    token: token,
  });

  handleError(data.header);
}

export async function submitKYCRequirement(
  type: 'IdCard' | 'Selfie' | 'Questionnaire',
  params: {
    file?: File;
    deposit_currency_id?: number;
    send_currency_id?: number;
  },
) {
  const form = new FormData();
  form.append('file', params.file as unknown as Blob);
  form.append('kycRequirementType', type);

  if (params.deposit_currency_id)
    form.append(
      'userRequirementQuestionaire.usualDepositCurrencyId',
      `${params.deposit_currency_id}`,
    );
  if (params?.deposit_currency_id)
    form.append(
      'userRequirementQuestionaire.usualSendCurrencyId',
      `${params.send_currency_id}`,
    );

  const { data } = await api.post<APIResponse>(
    '/api/user/UserProfile/AddUserKycRequirement',
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  handleError(data.header);
}

export async function updateProfileLocale(locale: string) {
  const { data: result } = await api.put<APIResponse>(
    '/api/user/UserProfile/EditUserLocale',
    {},
    {
      params: {
        locale: locale,
      },
    },
  );

  handleError(result.header);

  return;
}

export async function getOpenKYCCase() {
  const { data: result } = await api.get<GetOpenKycResponse>(
    '/api/user/UserProfile/GetUserOpenKycRequest',
  );

  handleError(result.header);

  type Requirement =
    GetOpenKycResponse['data']['userOpenKycRequestRequirements'][0];

  const requirements = {} as unknown as {
    [key in Requirement['requirementType']]: {
      status: Requirement['kycRequirementStatus'];
      reject_reason: string;
    };
  };

  result.data.userOpenKycRequestRequirements.forEach((row) => {
    requirements[row.requirementType] = {
      status: row.kycRequirementStatus,
      reject_reason: row.rejectReason,
    };
  });

  return {
    status: result.data.userKycRequestStatus,
    requirements,
  };
}

export async function getAccountInsight() {
  const { data: result } = await api.get<GetAccountInsightResponse>(
    '/api/user/Dashboard/CurrentUsage',
  );

  handleError(result.header);

  return {
    send_count_per_month_limit: result.data.maxAllowedSendOrder,
    send_count_per_month_usage: result.data.usedSendOrder,
    send_amount_per_month_limit: result.data.maxTotalSendOrderInUsdt,
    send_amount_per_month_usage: result.data.usedSendOrderSummationInUsdt,
    non_compliance_days_limit: result.data.kycNonComplianceDaysLimit,
    non_compliance_days_remained: Math.max(
      Math.floor(result.data.remainedDaysInNonCompliant),
      0,
    ),
    non_compliance_end_date: result.data.nonCompliantEnd,
    month_days_limit: result.data.daysInMonth,
    month_days_remained: Math.max(
      Math.floor(result.data.remainedDaysInMonth),
      0,
    ),
    month_end_date: result.data.currentMonthEnd,
    month_start_date: result.data.currentMonthStart,
    unlimited_non_compliance_days_limit:
      result.data.unlimitedKycNonComplianceDays,
    unlimited_send_order_per_month: result.data.unlimitedSendOrderPerMonth,
    unlimited_amount_on_send_order_per_month:
      result.data.unlimitedAmountSendOrderPerMonth,
  };
}

export async function getWalletsInsight() {
  const { data: result } = await api.get<GetWalletsInsightResponse>(
    '/api/user/UserWallet/GetUserWalletsInsight',
  );

  handleError(result.header);

  return {
    balance_in_usdt: result.data.totalWalletsAmountInUsdt,
    credit_balance: result.data.creditBalance,
    debit_balace: result.data.debitBalance,
    account_balance: result.data.accountBalance,
    deposit: result.data.deposit,
    wallets: result.data.walletsBalance.map((wallet) => {
      return {
        currency_iso_code: wallet.currencyIsoCode,
        balance: wallet.totalBalance,
        currency_name: wallet.currencyName,
      };
    }),
  };
}
export async function getProfile() {
  const { data: result } = await api.get<GetProfileResponse>(
    '/api/user/UserProfile/GetProfile',
  );

  handleError(result.header);

  const profile = result.data;

  return {
    user_id: profile.id,
    first_name: profile.firstName,
    last_name: profile.lastName,
    company_name: profile.companyName,
    email: profile.email,
    gender: profile.gender,
    is_company: profile.isCompany,
    kyc_status: profile.currentKycStatus,
    compliant_expiry_date: profile.nonCompliantExpiryDate,
    compliant_limit: profile.kycNonComplianceDaysLimit,
    birth_date: profile.birthDate,
    avatar: getAvatarUrl(profile.avatar),
    address1: profile.address1,
    country_name: profile.country,
    country_id: profile.countryId,
    country_code: profile.countryCode,
    postal_code: profile.postCode,
    state: profile.state,
    city: profile.city,
    phone_number: profile.rawPhoneNumber,
    locale: profile.locale,
    last_activity: profile.lastActivity,
    is_2fa: profile.twoFa,
    code: profile.code,
    address2: profile.address2,
    group: profile.customerGroupName,
    current_kyc_level: profile.currentKycLevelName,
    partnership_status: profile.partnerShipStatus,
    country_currency_iso_code: profile.currencyIsoCode,
    id_card_number: profile.idCardNumber,
    kyc_level: profile.stableKycLevelName,
    is_on_boarded: profile.isOnBoarded,
    dial_prefix: profile.phoneNumberDialPrefix,
  };
}

export async function updatePushToken(token: string) {
  const { data: result } = await api.put<APIResponse>(
    '/api/user/UserProfile/UpdateFcmToken',
    {},
    {
      params: {
        fcm: token,
      },
    },
  );

  handleError(result.header);

  return;
}

export async function setNotificationRead(id?: number) {
  const { data: result } = await api.put<APIResponse>(
    '/api/user/Notification/Read',
    {},
    {
      params: {
        notificationId: id ?? undefined,
      },
    },
  );
  handleError(result.header);

  return;
}

export async function getUnreadNotifCount() {
  const { data: result } = await api.post<GetUnreadNotifCountResponse>(
    '/api/user/Notification/CountUnread',
  );

  handleError(result.header);

  return {
    consumer: result.data.customer,
    partner: result.data.partner,
  };
}

export async function getNotifications(params?: {
  rows_per_page?: number;
  page?: number;
  type?: 'consumer' | 'partner';
}) {
  const { data: result } = await api.post<GetNotificationListResponse>(
    '/api/user/Notification/GetNotificationList',
    {
      isAscCreationDate: false,
      notificationTypes: [],
      isPartner:
        params?.type == 'partner'
          ? true
          : params?.type == 'consumer'
            ? false
            : null,
      pageNumber: params?.page ?? 1,
      pageSize: params?.rows_per_page ?? 10,
    },
  );

  handleError(result.header);

  return {
    total: result.data.total,
    notifications: result.data.data.map((notif) => {
      return {
        id: notif.id,
        type: notif.notificationType,
        create_data: notif.creationDate,
        message: notif.message,
        rel_id:
          notif.orderCode ?? notif.transactionId ?? notif.blockTransactionId,
        target: notif.isPartner ? 'partner' : 'consumer',
        is_read: notif.isRead,
      };
    }),
  };
}

export async function signup(params: {
  email: string;
  password: string;
  captcha: AppCaptchaValueModel;
}) {
  const { data } = await api.post<SignInRequestResponse>('/api/SigninRequest', {
    email: params.email,
    password: params.password,
    captchaValidationRequestModel: {
      content: params?.captcha?.content,
      uid: params?.captcha?.uid,
      captchaType: params?.captcha?.type,
    },
  });

  handleError(data.header);

  return data.data.firstStepToken;
}

export async function resetPassword(params: {
  email: string;
  otp: number;
  password: string;
  captcha: AppCaptchaValueModel;
}) {
  const { data } = await api.post<APIResponse>('/api/ResetPassword', {
    email: params.email,
    otp: params.otp,
    newPassword: params.password,
    captchaValidationRequestModel: {
      content: params?.captcha?.content,
      uid: params?.captcha?.uid,
      captchaType: params?.captcha?.type,
    },
  });

  handleError(data.header);
}
export async function recoverPassword(params: {
  email: string;
  captcha: AppCaptchaValueModel;
}) {
  const { data } = await api.post<APIResponse>('/api/ForgetPassword', {
    email: params.email,
    captchaValidationRequestModel: {
      content: params?.captcha?.content,
      uid: params?.captcha?.uid,
      captchaType: params?.captcha?.type,
    },
  });

  handleError(data.header);
}

export async function changePassword(params: {
  current_password: string;
  new_password: string;
  captcha: AppCaptchaValueModel;
}) {
  const { data } = await api.post<APIResponse>(
    '/api/user/UserProfile/ChangePassword',
    {
      currentPassword: params.current_password,
      newPassword: params.new_password,
      captchaValidationRequestModel: {
        content: params?.captcha?.content,
        uid: params?.captcha?.uid,
        captchaType: params?.captcha?.type,
      },
    },
  );

  handleError(data.header);
}

export async function signUp(params: {
  email: string;
  password: string;
  captcha: AppCaptchaValueModel;
}) {
  const { data: result } = await api.post<SignUpResponse>('/api/SignUp', {
    email: params?.email,
    password: params?.password,
    captchaValidationRequestModel: {
      content: params?.captcha?.content,
      uid: params?.captcha?.uid,
      captchaType: params?.captcha?.type,
    },
  });

  handleError(result.header);

  return {
    first_step_token: result.data.firstStepToken,
  };
}

export async function getGlobalTerms() {
  const { data: result } = await api.get<GetGlobalTermsResponse>(
    '/api/GetServiceTerms',
  );

  handleError(result.header);

  return {
    service: sanitizeHTML(result.data.serviceTerms),
    privacy: sanitizeHTML(result.data.privacyPolicy),
  };
}

export async function verifyIDNumber(user: {
  birth_date: string;
  id_card_number: string;
}) {
  const { data: result } = await api.put<VerifyIDNumberResponse>(
    '/api/user/UserProfile/NationalCodeInquery',
    {
      birthDate: user.birth_date,
      idCardNumber: user.id_card_number,
    },
  );

  handleError(result.header);

  return {
    first_name: result.data.firstName,
    last_name: result.data.lastName,
    father_name: result.data.fatherName,
    aliveness: result.data.aliveness,
    gender: result.data.gender,
  };
}

export async function requestSignIn(params: {
  email: string;
  password: string;
  captcha: AppCaptchaValueModel;
}) {
  const { data: result } = await api.post<SignInRequestResponse>(
    '/api/SigninRequest',
    {
      email: params.email,
      password: params.password,
      captchaValidationRequestModel: {
        content: params?.captcha?.content,
        uid: params?.captcha?.uid,
        captchaType: params?.captcha?.type,
      },
    },
  );

  handleError(result.header);

  return {
    token: result.data.token,
    refresh_token: result.data.refreshToken,
    first_step_token: result.data.firstStepToken,
    signalr_token: result.data.signalrToken,
  };
}

export async function toggle2FA() {
  const { data } = await api.post<SignInRequestResponse>(
    '/api/user/UserProfile/ToggleTwoStepAuthentication',
    {},
  );

  handleError(data.header);

  return;
}

export async function signIn(form: { token: string; otp: number }) {
  const { data: result } = await api.post<SignInResponse>('/api/Signin', {
    token: form.token,
    code: form.otp,
  });

  handleError(result.header);

  return {
    token: result.data.token,
    refresh_token: result.data.refreshToken,
    signalr_token: result.data.signalrToken,
  };
}

export async function uploadAvatar(form: FormData) {
  const { data } = await api.post<UploadAvatarResponse>(
    '/api/user/UserProfile/UploadProfilePicture',
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  handleError(data.header);

  return data.data;
}

// export async function uploadKYC(
//   file_selfie: string | Blob,
//   file_identity: string | Blob,
//   file_address: string | Blob,
// ) {
//   const form = new FormData();
//   form.append('fileSelfie', file_selfie);
//   form.append('fileAddress', file_address);
//   form.append('fileIdentity', file_identity);

//   const { data } = await api.post<APIResponse>(
//     '/api/user/UserProfile/UploadKyc',
//     form,
//     {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     },
//   );

//   handleError(data.header);

//   return;
// }
