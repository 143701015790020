import { api } from 'boot/axios';
import {
  APIBlobResponse,
  CheckCryptoWalletResponse,
  CreateWalletResponse,
  GetCryptoWalletDetailResponse,
  GetCurrencyListResponse,
  GetTransactionListResponse,
  GetWalletsResponse,
  RequestCryptoWalletCheckResponse,
} from 'src/types/responses';
import { handleError } from 'src/helpers';

export async function getWalletsCurrencies() {
  const { data } = await api.get<GetCurrencyListResponse>(
    '/api/user/UserWallet/GetCurrencyList',
  );

  handleError(data.header);

  return data.data.map((currency) => {
    return {
      id: currency.id,
      iso_code: currency.isoCode,
      name: currency.name,
      is_exists: currency.isExist,
    };
  });
}

export async function toggleWalletArchiveState(wallet_code: string) {
  const { data } = await api.put<CreateWalletResponse>(
    '/api/user/UserWallet/UserWalletIsArchivedToggle',
    null,
    {
      params: { walletCode: wallet_code },
    },
  );

  handleError(data.header);

  return data.data;
}

export async function createWallet(id: number) {
  const { data } = await api.post<CreateWalletResponse>(
    '/api/user/UserWallet/CreateUserWallet',
    null,
    {
      params: { currencyId: id },
    },
  );

  handleError(data.header);

  return data.data;
}

// export async function acceptTetherTerms(wallet_code: string) {
//   const { data } = await api.put<APIResponse>(
//     '/api/user/UserWallet/AcceptCryptoWalletAgreement',
//     null,
//     {
//       params: { walletCode: wallet_code },
//     },
//   );

//   handleError(data.header);

//   return;
// }

export async function getTransactions(params: {
  order_code?: string;
  order_type?: string;
  sign_type?: string;
  wallet_code?: string;
  rows_per_page: number;
  page: number;
  create_date?: {
    date_from: string;
    date_to: string;
  };
}) {
  let orderType = [] as unknown as string[];
  if (params?.order_type) {
    orderType = [params?.order_type];
  }
  let signType = null as unknown as string;
  if (params?.sign_type) {
    signType = params?.sign_type;
  }
  let walletCode = null as unknown as string;
  if (params?.wallet_code) {
    walletCode = params?.wallet_code;
  }
  const { data: result } = await api.post<GetTransactionListResponse>(
    '/api/user/Transaction/GetTransactionList',
    {
      orderType: orderType,
      signType: signType,
      isAsc: false,
      walletCode: walletCode,
      orderCode: params?.order_code,
      pageSize: params?.rows_per_page,
      pageNumber: params?.page,
      startDate: params?.create_date?.date_from
        ? new Date(params?.create_date?.date_from + ' 00:00:00').toISOString()
        : undefined,
      endDate: params?.create_date?.date_to
        ? new Date(params?.create_date?.date_to + ' 23:59:59').toISOString()
        : undefined,
    },
  );

  handleError(result.header);

  return {
    total: result.data.total,
    transactions: result.data.data.map((row) => {
      // let convert;
      // if (row.transactionConvertDetail) {
      //   const temp = row.transactionConvertDetail;

      //   convert = {
      //     dist_currency_id: temp.destinationCurrencyId,
      //     dist_currency_iso_code: temp.destinationCurrencyIsoCode,
      //     src_currency_id: temp.sourceCurrencyId,
      //     src_currency_iso_code: temp.sourceCurrencyIsoCode,
      //     is_fee: temp.isConversionFee,
      //   };
      // }

      // let move;
      // if (row.transactionMoveDetail) {
      //   const temp = row.transactionMoveDetail;

      //   move = {
      //     recipient_alias_name: temp.recipientAliasName,
      //     sender_first_name: temp.senderFirstName,
      //     sender_last_name: temp.senderLastName,
      //     sender_company_name: temp.senderCompanyName,
      //     sender_is_company: temp.senderIsCompany,
      //     sender_payrafi_id: temp.senderPayrafiCode,
      //     destination_currency_iso_code: temp.destinationCurrencyIsoCode,
      //   };
      // }

      // let send;
      // if (row.transactionSendDetail) {
      //   const temp = row.transactionSendDetail;

      //   send = {
      //     recipient_alias_name: temp.recipientAliasName,
      //     destination_currency_iso_code: temp.destinationCurrencyIsoCode,
      //   };
      // }

      return {
        id: row.transactionId,
        title: row.transactionTitle,
        amount: row.amount,
        currency_iso_code: row.currencyIsoCode,
        create_date: row.creationDate,
        wallet_code: row.walletCode,
        description: row.description,
        balance: row.balanceBeforeTransaction,
        is_fee: row.isFee,
        is_owner: row.isOwner,
        order_code: row.orderCode,
        order_number: row.orderId,
        order_type: row.orderType,
        order_gateway_type: row.orderGateway,
        // convert,
        // move,
        // send,
      };
    }),
  };
}

export async function getTransactionsCSV(params: {
  wallet_code?: string;
  create_date?: {
    date_from: string;
    date_to: string;
  };
}) {
  const { data: result } = await api.post<APIBlobResponse>(
    '/api/user/Transaction/GetCsvTransactionList',
    {
      orderType: [],
      isAsc: false,
      walletCode: params?.wallet_code,
      startDate: params?.create_date?.date_from
        ? new Date(params?.create_date?.date_from + ' 00:00:00').toISOString()
        : undefined,
      endDate: params?.create_date?.date_to
        ? new Date(params?.create_date?.date_to + ' 23:59:59').toISOString()
        : undefined,
    },
    {
      responseType: 'blob',
    },
  );

  if ('header' in result && result?.header?.responseCode)
    handleError(result.header);

  return result;
}

export async function getWallets(number?: string) {
  let params: unknown = {};
  if (number) {
    params = { walletCode: number };
  } else {
    params = {};
  }

  const { data } = await api.get<GetWalletsResponse>(
    '/api/user/UserWallet/GetUserWallets',
    { params: params },
  );

  handleError(data.header);

  return data.data
    .sort(function (a, b) {
      //a comes first
      if (a.currencyIsoCode == 'USDT') return -1;
      //b comes first
      if (b.currencyIsoCode == 'USDT') return 1;

      if (a.balance != b.balance) return Number(b.balance) - Number(a.balance);

      if (a.currencyIsoCode < b.currencyIsoCode) {
        return -1;
      }
      if (a.currencyIsoCode > b.currencyIsoCode) {
        return 1;
      }
      return 0;
    })
    .map((row) => {
      return {
        balance: row.balance,
        code: row.code,
        currency_name: row.currencyName,
        currency_iso_code: row.currencyIsoCode,
        currency_id: row.currencyId,
        is_archive: row.isArchived,
      };
    });
}

export async function requestCryptoWalletCheck(wallet_code: string) {
  const { data } = await api.put<RequestCryptoWalletCheckResponse>(
    '/api/user/CryptoWalletCheckRequest/SubmitCryptoWalletCheckRequest',
    null,
    {
      params: { walletCode: wallet_code },
    },
  );

  handleError(data.header);

  return data.data;
}

export async function checkCryptoWallet(request_id: number) {
  const { data: result } = await api.get<CheckCryptoWalletResponse>(
    '/api/user/CryptoWalletCheckRequest/GetCheckRequestResult',
    {
      params: {
        requestId: request_id,
      },
    },
  );
  handleError(result.header);

  return {
    status: result.data.cryptoWalletCheckRequestStatus,
    transactions: result.data.transactions.map((row) => {
      return {
        tx_id: row.txId,
        amount: row.amount,
      };
    }),
  };
}
export async function getCryptoWallet(wallet_code: string) {
  const { data: result } = await api.get<GetCryptoWalletDetailResponse>(
    '/api/user/UserWallet/GetCryptoWalletDetail',
    {
      params: {
        walletCode: wallet_code,
      },
    },
  );
  handleError(result.header);

  return {
    address: result.data.address,
    type: result.data.networkType,
    is_agreement_accepted: result.data.isAgreementAccepted,
  };
}
