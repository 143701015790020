import axios, { AxiosError, AxiosInstance } from 'axios';
import { boot } from 'quasar/wrappers';
import package_info from 'src/../package.json';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
// const $auth = useAuth();

const api = axios.create({
  baseURL: process.env.API_BASEURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'App-Version': package_info.version,
  },
});

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  //validate response
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      if (error !== undefined && error?.response?.status === 401) {
        const $auth = useAuth();
        $auth.login_state = false;
      }
      return Promise.reject(error);
    },
  );

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
